<template>
    <article class="proyecto-rsc row-end" v-if="data">
        <div class="contenedor-imagen" :style="`background:url(${data.image.url}) center/cover no-repeat`">
        </div>
        <div class="contenedor-texto">
            <div class="green-box">
                <h2>{{ data.name }}</h2>
                <p>{{ data.header }}</p>
            </div>
            <router-link :to="'/' + $t('paths.proyectos-rsc') + '/' + data.slug" class="arrow-link">
                {{ $t('projects_rsc.result_projects.show_project') }}
            </router-link>
        </div>
    </article>
</template>

<script>
export default {
    name: 'proyecto-rsc',
    props: ['data']
}
</script>