<template>
  <div class="wrapper -proyectos-ayudas padding-menu -fondo-campo">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link to="/" style="pointer-events: none;">{{
          $t("navbar_menu.commitment.title")
        }}</router-link>
        <router-link :to="$route.path">{{
          $t("projects_rsc.title")
        }}</router-link>
      </div>
    </div>

    <section class="intro-page">
      <div class="container">
        <h1 class="waypoints">{{ $t("projects_rsc.title") }}</h1>
        <img
          :src="$t('projects_rsc.main_banner.img')"
          alt=""
          class="waypoints"
        />
        <div class="green-box">
          <h2>{{ $t("projects_rsc.main_banner.title") }}</h2>
          <div v-html="$t('projects_rsc.main_banner.text')"></div>
        </div>
      </div>
    </section>

    <section class="filtros">
      <div class="container row-start">
        <!-- <input type="text" class="buscador-texto waypoints" placeholder="Buscar proyecto" v-model="filter.search" @keyup.enter="search"> -->
        <select name="" id="" v-model="filter.category" class="waypoints">
          <option value="">{{ $t("projects_rsc.filters.default_opt") }}</option>
          <option :key="key" :value="n.id" v-for="(n, key) in categories">{{
            n.name
          }}</option>
        </select>
        <a @click="search" class="boton-verde waypoints">{{
          $t("projects_rsc.filters.btn_text")
        }}</a>
      </div>
    </section>

    <section class="contenedor-proyectos waypoints">
      <loading v-if="loadingView || loadingSetTime" />
      <div class="row-start container" v-if="entries !== null">
        <proyecto :key="key" :data="n" v-for="(n, key) in entries"></proyecto>
      </div>
      <div
        class="container"
        v-if="
          entries == null && loadingView == false && loadingSetTime == false
        "
      >
        <p>{{ $t("projects_rsc.result_projects.not_result") }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import proyecto from "@/components/proyecto-rsc.vue";
import loading from "@/components/loading.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "proyectos",
  created() {
    this.search({
      filter: {
        search: this.filter.search,
        category: this.filter.category,
        lang: parseInt(this.$t(`langs.${this.$route.params.lang}`)),
      },
    });
    this.requestCategories({
      filter: {
        lang: parseInt(this.$t(`langs.${this.$route.params.lang}`)),
      },
    });
  },
  data: () => ({
    data: [],
    filter: {
      search: "",
      category: "",
    },
    entries: null,
    loadingSetTime: false,
  }),
  computed: {
    ...mapGetters({
      categories: "getCategories",
      rscEntries: "getEntriesRsc",
      loadingView: "getLoading",
    }),
  },
  methods: {
    ...mapActions(["requestEntries", "requestCategories"]),
    search() {
      this.loadingSetTime = true;
      this.requestEntries({
        filter: {
          search: this.filter.search,
          category: this.filter.category,
          lang: parseInt(this.$t(`langs.${this.$route.params.lang}`)),
        },
      }).then(() => {
        this.entries = this.rscEntries(1);
      });
      this.loadingSetTime = false;
    },
  },
  components: {
    proyecto: proyecto,
    loading: loading,
  },
  watch: {
    "$route.params.lang": function(lang) {
      this.search({
        filter: {
          search: "",
          category: "",
          lang: parseInt(this.$t(`langs.${lang}`)),
        },
      });
      console.log(parseInt(this.$t(`langs.${this.$route.params.lang}`)))
      this.requestCategories({
        filter: {
          lang: parseInt(this.$t(`langs.${this.$route.params.lang}`)),
        },
      });
    },
  },
};
</script>
